<template>
  <div>
    <v-select :filter="fuseSearch" label="name" :options="hotels" v-bind="$attrs" @input="$emit('input', $event)">
      <template v-slot:option="option"> {{ option.name }} ({{ option.dnsName }}) </template>
    </v-select>
  </div>
</template>

<script type="ts">
import {Component, Vue} from "vue-property-decorator";
import Fuse from "fuse.js";
import vSelect from 'vue-select';

@Component({
  components: {
    vSelect
  },
  props: {
    value: {
      type: [Object],
      default: null
    }
  }
})
export default class HotelSelector extends Vue {
  get hotels() {
    return this.$store.getters["hotel/hotels"];
  }
  fuseSearch(options, search) {
    const fuse = new Fuse(options, {
      keys: ['name', 'dnsName'],
      shouldSort: true
    });
    return search.length > 0 ? fuse.search(search).map(({item}) => item)
        : fuse.list


  }
  mounted() {
    this.$store.dispatch("hotel/getHotels");
  }
}
</script>

<style scoped></style>
